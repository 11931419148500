var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.getSessionMembers,
      pagination: false,
    },
    scopedSlots: _vm._u([
      {
        key: "startTimeRender",
        fn: function (text) {
          return _c("div", {}, [_vm._v("\n    " + _vm._s(text) + "\n  ")])
        },
      },
      {
        key: "handlers",
        fn: function (text, record) {
          return _c("div", { staticClass: "gx-text-right" }, [
            _vm.sessionData.is_recurring == 0 ||
            _vm.sessionData.payment_recurring == 1
              ? _c(
                  "div",
                  [
                    record.schedule && record.schedule.id
                      ? _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "top",
                              "ok-text": "Yes",
                              "cancel-text": "No",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.removeMemberAttendance(record)
                              },
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "title" },
                              [
                                _c("p", [
                                  _vm._v("Please select the Refund type"),
                                ]),
                                _c(
                                  "a-radio-group",
                                  {
                                    attrs: { name: "radioGroup" },
                                    model: {
                                      value: _vm.refundType,
                                      callback: function ($$v) {
                                        _vm.refundType = $$v
                                      },
                                      expression: "refundType",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "partial" } },
                                      [_vm._v(" Partial ")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "full" } },
                                      [_vm._v(" Full ")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.refundType !== "full"
                                  ? _c("a-input", {
                                      staticClass: "gx-mt-3",
                                      attrs: {
                                        step: "0.01",
                                        placeholder: "Enter value to be refund",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.amount,
                                        callback: function ($$v) {
                                          _vm.amount = $$v
                                        },
                                        expression: "amount",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              {
                                staticClass: "gx-btn-danger gx-mb-0",
                                attrs: {
                                  icon: "check",
                                  loading:
                                    _vm.select_member === record.member.id &&
                                    _vm.loader,
                                  disabled:
                                    _vm.select_member === record.member.id &&
                                    _vm.loader,
                                },
                              },
                              [_vm._v("Mark Absent")]
                            ),
                          ],
                          2
                        )
                      : _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "top",
                              "ok-text": "Yes",
                              "cancel-text": "No",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.createNewSchedule(record)
                              },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c("p", [_vm._v("Are you sure to mark Present")]),
                            ]),
                            _c(
                              "a-button",
                              {
                                staticClass: "gx-btn-success gx-mb-0",
                                attrs: {
                                  loading:
                                    _vm.select_member === record.member.id &&
                                    _vm.loading,
                                  disabled:
                                    _vm.select_member === record.member.id &&
                                    _vm.loading,
                                },
                              },
                              [_vm._v("Mark Present")]
                            ),
                          ],
                          2
                        ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    record.schedule && record.schedule.id
                      ? _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "top",
                              "ok-text": "Yes",
                              "cancel-text": "No",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.removeTeamMember(record)
                              },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c("p", [_vm._v("Are you sure to mark Absent")]),
                            ]),
                            _c(
                              "a-button",
                              {
                                staticClass: "gx-btn-success gx-mb-0",
                                attrs: {
                                  icon: "check",
                                  loading:
                                    _vm.select_member === record.member.id &&
                                    _vm.loader,
                                  disabled:
                                    _vm.select_member === record.member.id &&
                                    _vm.loader,
                                },
                              },
                              [_vm._v("\n          Present\n        ")]
                            ),
                          ],
                          2
                        )
                      : _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "top",
                              "ok-text": "Yes",
                              "cancel-text": "No",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.createNewSchedule(record)
                              },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c("p", [_vm._v("Are you sure to mark Present")]),
                            ]),
                            _c(
                              "a-button",
                              {
                                staticClass: "gx-btn-danger gx-mb-0",
                                attrs: {
                                  loading:
                                    _vm.select_member === record.member.id &&
                                    _vm.loading,
                                  disabled:
                                    _vm.select_member === record.member.id &&
                                    _vm.loading,
                                },
                              },
                              [_vm._v("Absent")]
                            ),
                          ],
                          2
                        ),
                    record.schedule && record.schedule.id
                      ? _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "top",
                              "ok-text": "Yes",
                              "cancel-text": "No",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.refundAmount(record)
                              },
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "title" },
                              [
                                _c("p", [
                                  _vm._v("Please select the Refund type"),
                                ]),
                                _c(
                                  "a-radio-group",
                                  {
                                    attrs: { name: "radioGroup" },
                                    model: {
                                      value: _vm.refundType,
                                      callback: function ($$v) {
                                        _vm.refundType = $$v
                                      },
                                      expression: "refundType",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "partial" } },
                                      [_vm._v(" Partial ")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "full" } },
                                      [_vm._v(" Full ")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.refundType !== "full"
                                  ? _c("a-input", {
                                      staticClass: "gx-mt-3",
                                      attrs: {
                                        step: "0.01",
                                        placeholder: "Enter value to be refund",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.amount,
                                        callback: function ($$v) {
                                          _vm.amount = $$v
                                        },
                                        expression: "amount",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              {
                                staticClass: "gx-btn-danger gx-mb-0",
                                attrs: {
                                  loading:
                                    _vm.select_member === record.member.id &&
                                    _vm.refundLoading,
                                  disabled:
                                    _vm.select_member === record.member.id &&
                                    _vm.refundLoading,
                                },
                              },
                              [_vm._v("Refund")]
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ])
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }